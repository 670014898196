import { createApp } from 'vue'
import AppComponent from '@/AppLk.vue'
import router from '@/router/lk-router'
import store from '@/store'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import NewValid from '@/Plugins/NewValid'
import Notifications from '@kyvg/vue3-notification'
import Session from '@/Plugins/Session/Session'
import TypeCurrency from '@/Plugins/TypeCurrency'
import LoaderComponent from '@/Plugins/Loader/Loader'
import ModalWindow from '@/Plugins/ModalWindow/Window'
import FormJoin from '@/Plugins/FormJoin/FormJoin'
import CopyData from '@/Plugins/CopyData'
import Digit from '@/Plugins/Digit'
import WorkDate from '@/Plugins/WorkForDate'
import EnteringData from '@/Plugins/EnteringData'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ru'
import CookieWork from '@/Plugins/CookieWork'
import FavIcon from '@/Plugins/favicon'
import Errors from '@/Plugins/Errors'
import eventBus from '@/Plugins/eventBus'
import VueSelect from 'vue-select'
import VueGtag from 'vue-gtag'
import VueYandexMetrika from 'vue3-yandex-metrika'
import VTooltip from 'floating-vue'
import 'floating-vue/dist/style.css'
import CopyToClipboard from '@/Plugins/CopyToClipboard'
import GetMessages from '@/i18n/i18n'
import I18nKeys from '@/Plugins/i18n'
import axios from 'axios'
import PrimeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/aura-light-green/theme.css'
import InlineSvg from 'vue-inline-svg'
import ExpandableBlock from '@/components/common/ExpandableBlock'

import { createI18n } from 'vue-i18n'

import { Buffer } from 'buffer'
import Dropdown from 'primevue/dropdown'
import ProgressSpinner from 'primevue/progressspinner'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import InputMask from 'primevue/inputmask'
import AutoComplete from 'primevue/autocomplete'
import VirtualScroller from 'primevue/virtualscroller'
import { Capacitor } from '@capacitor/core'

import { NativeBiometric } from "capacitor-native-biometric"

import 'tippy.js/dist/tippy.css'
import { App as CapacitorApp } from '@capacitor/app'
import { getQueryObjectFromString } from '@/utils/common'
import { getCashierInfoFromQuery } from '@/utils/cash'
import TreeTable from 'primevue/treetable'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'
import Panel from 'primevue/panel'

import { directive as TippyDirective } from 'vue-tippy'
import CeTextField from '@/components/common/CeTextField.vue'
import CeTextarea from '@/components/common/CeTextarea.vue'
import CePopup from '@/components/common/CePopup.vue'
import CeFileInput from '@/components/common/CeFileInput.vue'
import CeDropdown from '@/components/common/CeDropdown.vue'
import Calendar from 'primevue/calendar'
import CeCalendar from '@/components/common/CeCalendar.vue'

export let app

if (document.querySelector('#lk-app')) {

  window.Buffer = Buffer
  window.isAndroidApp = Capacitor?.getPlatform() === 'android'
// window.isAndroidApp = true

  app = createApp(AppComponent)

  const metaManager = createMetaManager()

// app.use(VueMeta)
  app.use(eventBus)
  app.use(metaManager)
  app.use(metaPlugin)
  app.use(NewValid)
  app.use(Notifications)
  app.use(Session)
  app.use(TypeCurrency)
  app.use(LoaderComponent)
  app.use(ModalWindow)
  app.use(FormJoin)
  app.use(CopyData)
  app.use(Digit)
  app.use(WorkDate)
  app.use(EnteringData)
// app.use(DatePicker)
  app.use(FavIcon)
  app.use(Errors)
  app.use(CookieWork)
  app.use(VTooltip)
  app.use(CopyToClipboard)
// app.use(VueI18n)
  app.use(I18nKeys)
  app.use(PrimeVue)

  app.use(store)
  app.use(router)

  app.component('DatePicker', DatePicker)
  app.component('VSelect', VueSelect)
  app.component('InlineSvg', InlineSvg)
  app.component('ExpandableBlock', ExpandableBlock)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('Dropdown', Dropdown)
  app.component('InputText', InputText)
  app.component('InputMask', InputMask)
  app.component('InputSwitch', InputSwitch)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('AutoComplete', AutoComplete)
  app.component('VirtualScroller', VirtualScroller)
  app.component('TreeTable', TreeTable)
  app.component('Column', Column)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('MultiSelect', MultiSelect)
  app.component('Checkbox', Checkbox)
  app.component('Panel', Panel)
  app.component('Calendar', Calendar)
  app.component('CeFileInput', CeFileInput)
  app.component('CeTextField', CeTextField)
  app.component('CeDropdown', CeDropdown)
  app.component('CeTextarea', CeTextarea)
  app.component('CeCalendar', CeCalendar)
  app.component('CePopup', CePopup)

  app.directive('tippy', TippyDirective)

  // app.use(VueGtag, {
  //   config: {
  //     id: 'UA-156684194-1'
  //   }
  // })

  app.use(VueYandexMetrika, {
    id: 66788152,
    env: 'production'
  })

  axios.defaults.timeout = 120_000

  GetMessages()
    .then(messages => {
      return createI18n({
        locale: 'en',
        fallbackLocale: 'en',
        globalInjection: true,
        legacy: false,
        messages
      })
    })
    .then(i18n => {
      app.use(i18n)
      // Обработчик для перезагрузки при ошибке загрузки чанка(например, после обновления)
      window.addEventListener('load', () => {
        // localStorage.removeItem('alreadyReloaded')
      })
      window.addEventListener('error', (err) => {
        if (!localStorage.getItem('alreadyReloaded')) {
          localStorage.setItem('alreadyReloaded', 'true')
          window.location.reload()
        }
      })
      return app.mount('#lk-app')
    })
}

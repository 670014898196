export const FORM_TYPE_NAMES = {
  INTERNAL: 'Internal',
  FIAT: 'AutoexchangeCash',
  ASSET: 'Asset',
  BILL: 'AutoexchangeBill'
}

export const FORM_TYPE_LABELS = {
  [FORM_TYPE_NAMES.FIAT]: 'Наличные получить/отдать',
  [FORM_TYPE_NAMES.BILL]: 'Оплатить счет',
  [FORM_TYPE_NAMES.INTERNAL]: 'Валюты в Cryptoenter',
  [FORM_TYPE_NAMES.ASSET]: 'Товары и услуги'
}

export const FORM_TYPES = Object.values(FORM_TYPE_NAMES).map(value => {
  return { value, label: FORM_TYPE_LABELS[value] }
})

export const EXTERNAL_FORM_TYPES = FORM_TYPES.filter(i => ![FORM_TYPE_NAMES.INTERNAL, FORM_TYPE_NAMES.ASSET].includes(i.value))

export const PAYMENT_TYPE_NAMES = {
  CASH: 'Cash',
  CARD_AMOUNT: 'CardAmount',
  // ACCOUNT_AMOUNT: 'AccountAmount',
  FOREIGN_ACCOUNT: 'AccountAmount'
}

export const PAYMENT_TYPES_LABEL = {
  [PAYMENT_TYPE_NAMES.CASH]: 'Наличные',
  [PAYMENT_TYPE_NAMES.CARD_AMOUNT]: 'Перевод на карту',
  // [PAYMENT_TYPE_NAMES.ACCOUNT_AMOUNT]: 'Перевод на счёт',
  [PAYMENT_TYPE_NAMES.FOREIGN_ACCOUNT]: 'Перевод на счёт',
}

export const PAYMENT_TYPES_ICON = {
  [PAYMENT_TYPE_NAMES.CASH]: 'cash.svg',
  [PAYMENT_TYPE_NAMES.CARD_AMOUNT]: 'bank-card.svg',
  // [PAYMENT_TYPE_NAMES.ACCOUNT_AMOUNT]: 'invoice.svg',
  [PAYMENT_TYPE_NAMES.FOREIGN_ACCOUNT]: 'invoice.svg',
}

export const PAYMENT_TYPES = Object.values(PAYMENT_TYPE_NAMES).map(paymentType => {
  return {
    label: PAYMENT_TYPES_LABEL[paymentType],
    value: paymentType,
    icon: PAYMENT_TYPES_ICON[paymentType]
  }
})

export const SELECTOR_VALUE_NAMES = {
  BUY: 'BUY',
  SELL: 'SELL'
}

export const SELECTOR_VALUE_LABELS = {
  [SELECTOR_VALUE_NAMES.BUY]: 'p2p_selector_buy',
  [SELECTOR_VALUE_NAMES.SELL]: 'p2p_selector_sell'
}

export const SELECTOR_VALUES = [
  {
    value: SELECTOR_VALUE_NAMES.BUY,
    label: 'p2p_selector_buy'
  },
  {
    value: SELECTOR_VALUE_NAMES.SELL,
    label: 'p2p_selector_sell'
  }
]

export const DEAL_STATUS_NAME = {
  WAIT_ACCEPT: 'WaitAccept',
  WAIT_PARTICIPANT_ACCEPT: 'WaitParticipantAccept',
  OPEN: 'Open',
  CLOSED: 'Closed',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
}

export const COMMON_DEAL_STATUS_NAMES = {
  OPENED: 'Open',
  CLOSED: 'Closed',
  CANCELED: 'CANCELED'
}

export const DEAL_STATUS_NAME_FIAT_CASH = {
  ...COMMON_DEAL_STATUS_NAMES,
  PAYMENT_TYPE_ACCEPTED_CASH: 'CashPaymentTypeAccepted',
  TOKEN_ACCEPTED: 'TokenAccepted',
  ADDRESS_ACCEPTED: 'AddressAccepted',
  HANDED_OVER_CASH: 'HandOverCash',
  GOT_CASH: 'GotCash',
}

const {
  PAYMENT_TYPE_ACCEPTED_CASH,
  TOKEN_ACCEPTED,
  ADDRESS_ACCEPTED,
  HANDED_OVER_CASH,
  GOT_CASH,
} = DEAL_STATUS_NAME_FIAT_CASH

export const DEAL_STATUS_NAME_FIAT_ACCOUNT = {
  ...COMMON_DEAL_STATUS_NAMES,
  PAYMENT_TYPE_ACCEPTED_ACCOUNT: 'TransferPaymentTypeAccepted',
  CASH_RECEIPT_LOADED: 'CheckLoaded',
  TRANSFER_RECEIVED: 'TransferReceived',
}

const {
  PAYMENT_TYPE_ACCEPTED_ACCOUNT,
  CASH_RECEIPT_LOADED,
  TRANSFER_RECEIVED,
} = DEAL_STATUS_NAME_FIAT_ACCOUNT

export const DEAL_STATUS_NAME_BILL = {
  ...COMMON_DEAL_STATUS_NAMES,
  AGENCY_AGREEMENT_ACCEPTED: 'AgencyAgreementAccepted',
  BILL_PAID: 'BillPaid',
  BILL_FUNDS_RECEIVED: 'BillFundsReceived',
  ACT_ACCEPTED: 'ActAccepted'
}

const {
  AGENCY_AGREEMENT_ACCEPTED,
  BILL_PAID,
  BILL_FUNDS_RECEIVED,
  ACT_ACCEPTED,
} = DEAL_STATUS_NAME_BILL

export const DEAL_STATUS_LABEL = {
  // для всех
  [COMMON_DEAL_STATUS_NAMES.OPENED]: 'Открыта',
  [COMMON_DEAL_STATUS_NAMES.CLOSED]: 'Закрыта',
  // для типа FIAT, когда способ оплаты CASH
  [PAYMENT_TYPE_ACCEPTED_CASH]: 'Способ оплаты подтвержден',
  [TOKEN_ACCEPTED]: 'Токен загружен',
  [ADDRESS_ACCEPTED]: 'Адрес подтвержден',
  [HANDED_OVER_CASH]: 'Наличные переданы',
  [GOT_CASH]: 'Наличные получены',
  // для типа FIAT, когда способ оплаты На карту/По номеру счета
  [PAYMENT_TYPE_ACCEPTED_ACCOUNT]: 'Способ оплаты подтвержден',
  [CASH_RECEIPT_LOADED]: 'Платеж отправлен',
  [TRANSFER_RECEIVED]: 'Платеж получен',
  // для типа BILL
  [AGENCY_AGREEMENT_ACCEPTED]: 'Документы загружены',
  [BILL_PAID]: 'Счет оплачен',
  [BILL_FUNDS_RECEIVED]: 'Деньги пришли',
  [ACT_ACCEPTED]: 'Акт выполненных работ загружен'
}

export const OFFER_STATUS_NAME = {
  OPEN: 'Open',
  CLOSED: 'Closed'
}

// export const DEAL_STATUS_LABEL = {
//   [DEAL_STATUS_NAME.WAIT_ACCEPT]: 'Ожидает вашего подтверждения',
//   [DEAL_STATUS_NAME.WAIT_PARTICIPANT_ACCEPT]: 'Ожидает подтверждения другим пользователем',
//   [DEAL_STATUS_NAME.OPEN]: 'Открыта',
//   [DEAL_STATUS_NAME.CLOSED]: 'Закрыта',
//   [DEAL_STATUS_NAME.ACCEPTED]: 'Подтверждена',
//   [DEAL_STATUS_NAME.REJECTED]: 'Отменена',
// }

export const PAYMENT_REASON_NAMES = {
  PRODUCT: 'PRODUCT',
  SERVICE: 'SERVICE',
}

export const PAYMENT_REASON_LABELS = {
  [PAYMENT_REASON_NAMES.SERVICE]: 'За услуги',
  [PAYMENT_REASON_NAMES.PRODUCT]: 'За товар'
}

export const PAYMENT_REASONS = Object.values(PAYMENT_REASON_NAMES).map(paymentReason => {
  return {
    label: PAYMENT_REASON_LABELS[paymentReason],
    value: paymentReason
  }
})

export const DEAL_ROLE_NAMES = {
  FIAT_CASH_BUYER: 'FIAT_CASH_BUYER',
  FIAT_CASH_SELLER: 'FIAT_CASH_SELLER',
  FIAT_ACCOUNT_BUYER: 'FIAT_ACCOUNT_BUYER',
  FIAT_ACCOUNT_SELLER: 'FIAT_ACCOUNT_SELLER',
  BILL_BUYER: 'BILL_BUYER',
  BILL_SELLER: 'BILL_SELLER',
}

export const RULE_TYPE_NAMES = {
  TRANSFER: 'Transfer',
  CASH: 'Cash',
  BILL: 'Bill'
}
